<template>
  <el-dialog title="设置员工"
             width="30%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="手机号码"
                    prop="tel">
        <el-select class="width100"
                   v-model="form.tel"
                   filterable
                   remote
                   placeholder="请输入员工手机号进行搜索"
                   :remote-method="remoteMethod"
                   :loading="selectLoading">
          <el-option v-for="item in options"
                     :key="item.id"
                     :label="item.label"
                     :value="item.tel">
            <span>{{`(${item.name})${item.tel}`}}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fetchEmployees } from '@/api/system'
import { setMemberLuckyManager } from '@/api/market'
export default {
  props: ['activity_id'],
  data () {
    return {
      form: {
        activity_id: this.activity_id,
        id: '',
        tel: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        p_user_id: [
          { required: true, type: 'number', message: '请选择所属员工号码', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.form = {
          activity_id: this.activity_id,
          tel: formData.tel,
          id: formData.id
        }
      } else {
        this.form = {
          activity_id: this.activity_id,
          tel: '',
          id: ''
        }
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setMemberLuckyManager(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.marketer_config_id ? '修改' : '新增'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchEmployees({
          page: 1,
          size: 5,
          search_type: 'TEL',
          search_keyword: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.marketers
        }
      } else {
        this.options = []
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
