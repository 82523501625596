<template>
  <div class="container">
    <table-page dataName="list"
                ref="table"
                :search="search"
                :params='{activity_id:$route.query.id}'
                @on-success="fetchSuccess"
                :request="getMemberLuckyManagers">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   class="mr-15"
                   @click="$refs.setEmployee.showModel()">添加员工</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportNetsData">导出网点数据</el-button>
        <FileUpload type="primary"
                    buttonType="icon"
                    :params="{import_type:'crm_activity_marketer_data',carry_data:{activity_id:$route.query.id}}"
                    v-permission="['ADMIN']">导入文件</FileUpload>
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="city"
                       show-overflow-tooltip
                       label="地市">
      </el-table-column>
      <el-table-column prop="district"
                       show-overflow-tooltip
                       label="区县">
      </el-table-column>
      <el-table-column prop="net"
                       show-overflow-tooltip
                       label="网点">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.setEmployee.showModel(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     v-permission="['ADMIN']"
                     icon="el-icon-delete"
                     @click="delPacketEmployees(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetEmployee ref="setEmployee"
                 @on-success="$refs.table.fetch()"
                 :activity_id="$route.query.id"></SetEmployee>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetEmployee from './components/SetEmployee'
import FileUpload from '@/components/FileUploadAsync'
import { getMemberLuckyManagers, delPacketEmployees } from '@/api/market'
export default {
  name: 'MemberLuckyEmployees',
  data () {
    return {
      exportUrl: '',
      getMemberLuckyManagers,
      search: [{ type: 'area' }, {
        type: 'select',
        value: '',
        key: 'search',
        name: '搜索类型',
        list: [
          {
            value: 'name',
            label: '姓名'
          },
          {
            value: 'tel',
            label: '手机号码'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'val',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    SetEmployee
  },
  methods: {
    fileSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          const str = res.data.not_marketer_arr.join(' ')
          this.$alert(`成功导入${res.data.succ_total}` + res.data.not_marketer_arr.length > 0 ? '失败' + str : '', '导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    exportNetsData () {
      window.open(this.exportUrl, '_blank')
    },
    setNet (id) {
      this.$router.push({
        name: 'SetNet',
        query: {
          id: id
        }
      })
    },
    delPacketEmployees (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delPacketEmployees({
          activity_id: this.$route.query.id,
          marketer_config_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
